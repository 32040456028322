import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import Education from "./Education/Education";
import styles from "./Educations.module.scss";

const Educations = ({ educations, onEducationOccasionActionSuccessful }) => {
  const translate = useTranslate();

  return (
    <div>
      <h2 className={styles.sectionTitle}>{translate("EDUCATIONS")}</h2>
      {educations.map(education => (
        <Education
          key={education.id}
          education={education}
          onEducationOccasionActionSuccessful={onEducationOccasionActionSuccessful}
        />
      ))}
    </div>
  );
};

Educations.propTypes = {
  educations: PropTypes.array,
  onEducationOccasionActionSuccessful: PropTypes.func,
};

export default Educations;
