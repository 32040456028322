import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function performEducationOccasionAction(educationOccasionId, action) {
  const url = api.education.educationOccasion(educationOccasionId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action }, await defaultRestOptions());
}

export default { performEducationOccasionAction };
