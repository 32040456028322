import PropTypes from "prop-types";
import { useTranslate } from "../../../../../language/i18n";
import LogisticsConfirmModal from "../../../../shared/Modals/LogisticsConfirmModal";
import styles from "./EducationDetailModal.module.scss";
import EducationOccasion from "./EducationOccasion/EducationOccasion";

const EducationDetailModal = ({ visible, onClose, education, onEducationOccasionActionSuccessful }) => {
  const translate = useTranslate();

  const userIsAlreadyRegistered = education.educationOccasions?.some(
    educationOccasion => educationOccasion.isRegistered,
  );

  return (
    <LogisticsConfirmModal
      title={education.title}
      text={education.description}
      visible={visible}
      cancel={onClose}
      small={false}
      showButtons={false}
    >
      <div className={styles.list}>
        <div className={styles.titles}>
          <span>{translate("EDUCATION_OCCASIONS")}</span>
          <span>{translate("NUMBER_OF_REGISTERED")}</span>
        </div>

        {education.educationOccasions.map(educationOccasion => (
          <EducationOccasion
            key={educationOccasion.id}
            educationOccasion={educationOccasion}
            onSubmit={onEducationOccasionActionSuccessful}
            isRegisteredOnEducation={userIsAlreadyRegistered}
          />
        ))}
      </div>
    </LogisticsConfirmModal>
  );
};

EducationDetailModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  education: PropTypes.object,
  onEducationOccasionActionSuccessful: PropTypes.func,
};

export default EducationDetailModal;
