import { Button } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslate } from "../../../../../../language/i18n";
import educationOccasionService from "../../../../../../services/educationOccasion/educationOccasionService";
import { EDUCATION_OCCASION_ACTION } from "../../../../../../utils/constants";
import { getDateFromTimeStamp, getTime, getTimestamp } from "../../../../../../utils/DateUtil";
import styles from "./EducationOccasion.module.scss";

const EducationOccasion = ({ educationOccasion, isRegisteredOnEducation, onSubmit: onSubmitProp }) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const isFullyBooked = educationOccasion.currentNumberOfAttendees >= educationOccasion.maxNumberOfAttendees;

  const onSubmit = useCallback(
    async action => {
      setIsLoading(true);
      const response = await educationOccasionService.performEducationOccasionAction(educationOccasion.id, action);
      if (response.isOk()) {
        onSubmitProp(educationOccasion.educationId, educationOccasion.id, action);
      }
      setIsLoading(false);
    },
    [educationOccasion.id, educationOccasion.educationId, onSubmitProp],
  );

  const buildEducationOccasionTime = () => {
    const { startDateTime, endDateTime } = educationOccasion;
    if (getDateFromTimeStamp(startDateTime) === getDateFromTimeStamp(endDateTime)) {
      return `${getTimestamp(startDateTime)} - ${getTime(endDateTime)}`;
    }
    return `${getTimestamp(startDateTime)} - ${getTimestamp(endDateTime)}`;
  };

  return (
    <div className={styles.row}>
      <span className={styles.date}>{buildEducationOccasionTime()}</span>
      <span>
        {isFullyBooked
          ? translate("FULLY_BOOKED")
          : `${educationOccasion.currentNumberOfAttendees}/${educationOccasion.maxNumberOfAttendees}`}
      </span>

      {isRegisteredOnEducation
        ? educationOccasion.isRegistered && (
            <div className={styles.button}>
              <Button onClick={() => onSubmit(EDUCATION_OCCASION_ACTION.DEREGISTER)} isLoading={isLoading}>
                {translate("DEREGISTER")}
              </Button>
            </div>
          )
        : !isFullyBooked && (
            <div className={styles.button}>
              <Button onClick={() => onSubmit(EDUCATION_OCCASION_ACTION.REGISTER)} isLoading={isLoading}>
                {translate("REGISTER")}
              </Button>
            </div>
          )}
    </div>
  );
};

EducationOccasion.propTypes = {
  educationOccasion: PropTypes.object,
  isRegisteredOnEducation: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EducationOccasion;
