import { Response } from "@myloc/myloc-utils";
import i18n from "../../language/i18n";
import { setError } from "../../reducers/dialog/dialogAction";
import { resetSession } from "../../reducers/session/sessionAction";
import errorCodes, { getMessageFromSessionErrorCodes } from "./errorCodes";

export function handleError(exception, errorHandler) {
  if (!errorHandler) return handleErrorResponse(exception);
  return errorHandler(exception);
}

const isResetExceptions = errorCode => {
  return (
    errorCode === errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE ||
    errorCode === errorCodes.SESSION_INVALID.USER_DISABLED.CODE
  );
};

const isNoUserMessageException = errorCode => {
  return (
    errorCode === errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE ||
    errorCode === errorCodes.SESSION_INVALID.USER_DISABLED.CODE
  );
};

const handleErrorResponse = exception => {
  let message = "";
  if (exception?.response?.status === 401) {
    if (isResetExceptions(exception.response.data)) {
      resetSession();
    }

    if (isNoUserMessageException(exception.response.data)) {
      return new Response(exception?.response?.status, null, exception);
    }
  }

  //First try to get error message from local error codes
  message = i18n.t(getMessageFromSessionErrorCodes(exception.response.data));

  if (!message) {
    message = getErrorMessage(exception);
  }

  setError({
    error: exception,
    errorMessage: message,
  });

  return new Response(exception?.response?.status, message, exception);
};

export function getErrorMessage(exception) {
  if (!exception || !exception?.response) {
    return navigator.onLine ? i18n.t("SERVER_ERROR_DESCRIPTION") : i18n.t("INTERNET_CONNECTION_ERROR_DESCRIPTION");
  } else if (exception?.response?.status === 500) {
    return i18n.t("SERVER_COULDNT_PROCESS");
  } else if (exception?.response?.status === 503) {
    return i18n.t("SERVER_UNAVAILABLE_DESCRIPTION");
  } else if (exception?.response?.data) {
    const contentType = exception.response.headers["content-type"]; //MOVE TO some util constant
    if (typeof exception.response.data === "object") {
      return exception.response.data.errorMessage.errorMessage;
    } else if (typeof exception.response.data === "string" && !contentType?.startsWith("test/html")) {
      //Move to some util constant
      return exception.response.data;
    }
  }
  return "Unknown server error";
}
