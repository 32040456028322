import { useEffect, useState } from "react";
import { LoginMethod, UI_LOGIN_METHODS } from "./useAvailableLoginMethods";

const useSelectedLoginMethod = (availableLoginMethods: LoginMethod[]) => {
  const [selectedLoginMethod, setSelectedLoginMethod] = useState<LoginMethod | undefined>(undefined);

  const reset = () => {
    setSelectedLoginMethod(undefined);
  };

  useEffect(() => {
    if (!selectedLoginMethod && availableLoginMethods.length === 1) {
      setSelectedLoginMethod(availableLoginMethods[0]);
    }
  }, [availableLoginMethods, selectedLoginMethod]);

  const isLoginFormSelected = selectedLoginMethod === UI_LOGIN_METHODS.LOGIN_FORM;
  const isBankIDSelected = selectedLoginMethod === UI_LOGIN_METHODS.BANKID;

  return {
    selectedLoginMethod,
    setSelectedLoginMethod,
    reset,
    isLoginFormSelected,
    isBankIDSelected,
  };
};

export default useSelectedLoginMethod;
