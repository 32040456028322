import PropTypes from "prop-types";
import { useState } from "react";
import EducationDetailModal from "../Modal/EducationDetailModal";
import styles from "./Education.module.scss";

const Education = ({ education, onEducationOccasionActionSuccessful }) => {
  const [educationModalVisible, setEducationModalVisible] = useState(false);

  return (
    <div className={styles.row}>
      <p className={styles.link} onClick={() => setEducationModalVisible(true)}>
        {education.title}
      </p>
      <EducationDetailModal
        visible={educationModalVisible}
        onClose={() => setEducationModalVisible(false)}
        education={education}
        onEducationOccasionActionSuccessful={onEducationOccasionActionSuccessful}
      />
    </div>
  );
};

Education.propTypes = {
  education: PropTypes.object,
  onEducationOccasionActionSuccessful: PropTypes.func,
};

export default Education;
