import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useAvailableLoginMethods, {
  NON_UI_LOGIN_METHODS,
} from "../components/Start/Welcome/hooks/useAvailableLoginMethods";
import { useAppDispatch } from "../reducers/hooks/useAppDispatch";
import { setIsSingleSignOn } from "../reducers/session/sessionAction";
import singleSignOnService from "../services/singleSignOn/singleSignOnService";
import storage from "../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE } from "../utils/store/storeVariables";

export const useIsSingleSignOn = () => {
  const isSingleSignOn = useSelector(state => state.session?.isSingleSignOn);
  const fetching = useRef(false);
  const dispatch = useAppDispatch();
  const { isAllowedLoginMethod, loginMethodsInitialized } = useAvailableLoginMethods();

  const getSSOUserData = useCallback(async () => {
    if (!isAllowedLoginMethod(NON_UI_LOGIN_METHODS.AZURE_AD)) {
      dispatch(setIsSingleSignOn(false));
      return;
    }

    if (fetching.current) return;

    fetching.current = true;

    const response = await singleSignOnService.getUserData();

    if (response.isOk() && response.data.id_token) {
      await storage.saveItem(SINGLE_SIGN_ON_RESPONSE, response.data.id_token, false);
      dispatch(setIsSingleSignOn(true));
    } else {
      dispatch(setIsSingleSignOn(false));
    }

    fetching.current = false;
  }, [dispatch, isAllowedLoginMethod]);

  useEffect(() => {
    if (isSingleSignOn === undefined && loginMethodsInitialized) {
      getSSOUserData();
    }
  }, [getSSOUserData, isSingleSignOn, loginMethodsInitialized]);

  return { isSingleSignOn };
};
