import { useTranslate } from "../../language/i18n";
import Page from "../shared/Page/Page";
import styles from "./ErrorPages.module.scss";

const ERROR_TYPE = {
  PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  USER_NOT_AUTHENTICATED: "USER_NOT_AUTHENTICATED",
  USER_NOT_ALLOWED: "USER_NOT_ALLOWED",
} as const;

type ErrorType = typeof ERROR_TYPE[keyof typeof ERROR_TYPE];

interface ErrorPageProps {
  type: ErrorType;
}

const ErrorPage = ({ type }: ErrorPageProps) => {
  const translate = useTranslate();

  return (
    <Page>
      <h1 className={styles.title}>{translate(type)}</h1>
      <p className={styles.description}>{translate(`${type}_DESCRIPTION`)}</p>
      <p className={styles.description}>{translate("CONTACT_SUPPORT_IF_ERROR_PERSISTS")}</p>
    </Page>
  );
};

export default ErrorPage;
export { ERROR_TYPE };
