import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { HOS, HUL, myloc, UMEA } from "../../../config/settings";
import { useTranslate } from "../../../language/i18n";
import { formatPostalNo } from "../../../utils/postalNo";
import { isAT, isHOS, isHUL, isUMEA } from "../../../utils/siteEvaluator";
import { ExternalLink, Target, Type } from "../../shared/Link";
import styles from "./Footer.module.scss";

const source = isHOS() ? HOS : isAT() ? myloc : isUMEA() ? UMEA : isHUL() ? HUL : null;

function Footer() {
  const userLoggedIn = !!useSelector(state => state.session.sessionId);

  return userLoggedIn ? (
    <footer className={styles.footerMinimal}>
      <div className={styles.versionContent}>
        <div className={styles.version}>Version: {process.env.REACT_APP_VERSION}</div>
      </div>
      {isHUL() && (
        <div className={styles.supportEmailContent}>
          <SupportEmail />
        </div>
      )}
    </footer>
  ) : (
    <footer className={styles.footer}>
      <div className={classNames(styles.content, styles.containerPadding)}>
        <Contact />
        <Links />
        <Address />
      </div>
    </footer>
  );
}

const Contact = () => {
  const translate = useTranslate();
  return (
    <div>
      <Title>{translate("CONTACT")}</Title>
      {source?.contactPhone != null && (
        <ExternalLink url={source.contactPhone} type={Type.PHONE} customCssClass={styles.text}>
          {translate("PHONE_SHORT")}: {source.contactPhone.toString}
        </ExternalLink>
      )}
    </div>
  );
};

const SupportEmail = () => {
  if (source?.supportEmail == null) return null;

  return (
    <ExternalLink url={source.supportEmail} type={Type.EMAIL} customCssClass={styles.text}>
      {source.supportEmail.toString}
    </ExternalLink>
  );
};

const Links = () => {
  const translate = useTranslate();
  return (
    <div className={classNames(styles.section)}>
      <Title>{translate("SUPPORT")}</Title>

      <SupportEmail />
      {source?.cookieIntegrityUrl != null && (
        <ExternalLink url={source.cookieIntegrityUrl} target={Target.NEW_TAB} customCssClass={styles.text}>
          {translate("COOKIE_POLICY")}
        </ExternalLink>
      )}
      <Version />
    </div>
  );
};

const Version = () => {
  return (
    <div>
      <Text>Version: {process.env.REACT_APP_VERSION}</Text>
    </div>
  );
};
const Address = () => {
  const translate = useTranslate();
  return (
    <div className={styles.address}>
      <Title>{translate("ADDRESS")}</Title>
      {source?.address != null && (
        <>
          <Text>{source.address.street}</Text>
          <Text>{`${formatPostalNo(source.address.postalNo)} ${source.address.city}`}</Text>
          <Text>{source.address.county}</Text>
        </>
      )}
    </div>
  );
};

const Title = ({ children }) => <p className={styles.title}>{children}</p>;
const Text = ({ children }) => <p className={styles.text}>{children}</p>;

Footer.propTypes = {
  session: PropTypes.object,
  user: PropTypes.object,
};

Text.propTypes = {
  children: PropTypes.node,
};

Title.propTypes = {
  children: PropTypes.node,
};

export default Footer;
