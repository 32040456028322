import { Button, Form, InputField, THEME } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { pages } from "../../../config/settings";
import accountService from "../../../services/account/accountService";
import pageDefinitions from "../../../utils/pages";
import RequestResetPassword from "../ResetPassword/RequestResetPassword";
import styles from "./Login.module.scss";

const Login = ({ desiredPage }) => {
  const translate = useTranslation().t;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      setUsername(process.env.REACT_APP_DEV_USER);
      setPassword(process.env.REACT_APP_DEV_PWD);
    }
  }, []);

  async function submitLogin(event) {
    event.preventDefault();
    setLoading(true);
    const response = await accountService.login({ username, password });

    if (response.isOk()) {
      if (desiredPage && pages.allowRedirectionToAfterLogin(desiredPage)) {
        history.push(desiredPage);
      } else {
        history.push(pageDefinitions.HOME);
      }
    } else {
      setLoading(false);
    }
  }

  return resetPasswordVisible ? (
    <RequestResetPassword onClose={setResetPasswordVisible} />
  ) : (
    <>
      <Form className={styles.login} onSubmit={submitLogin}>
        <h2 className={styles.title}>{translate("LOGIN")}</h2>
        <InputField
          required
          label={translate("USERNAME")}
          value={username}
          onChange={event => setUsername(event.target.value)}
          autoFocus
          customCssClass={styles.input}
          onClear={() => setUsername("")}
        />
        <InputField
          required
          label={translate("PASSWORD")}
          type={"password"}
          value={password}
          onChange={event => setPassword(event.target.value)}
          customCssClass={styles.input}
          onClear={() => setPassword("")}
        />

        <div className={styles.actionBar}>
          <Button isLoading={isLoading} type="submit" customCssClass={styles.loginButton}>
            {translate("LOGIN")}
          </Button>

          <Button
            theme={THEME.PLAIN_PRIMARY}
            customCssClass={styles.passwordButton}
            onClick={() => setResetPasswordVisible(true)}
          >
            {translate("FORGOT_PASSWORD") + "?"}
          </Button>
        </div>
      </Form>
    </>
  );
};

Login.propTypes = {
  forgotPasswordUrl: PropTypes.string,
  registerUrl: PropTypes.string,
  desiredPage: PropTypes.string,
};

export default Login;
