import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectHasComflowAccess } from "../../../reducers/session/sessionSelectors";
import pageContentService from "../../../services/pageContent/pageContentService";
import { EDUCATION_OCCASION_ACTION } from "../../../utils/constants";
import { useTablet } from "../../../utils/viewport";
import LinkToComflow from "../../Comflow/LinkToComflow";
import FileDownloadList from "../../shared/File/FileDownload/FileDownload";
import { ExternalLinkList, Target } from "../../shared/Link";
import styles from "./ContentWelcome.module.scss";
import Educations from "./Educations/Educations";
import Welcome from "./Welcome";

const ContentWelcome = () => {
  const [pageContent, setPageContent] = useState({});
  const notDesktop = useTablet();
  const hasComflowAccess = useSelector(selectHasComflowAccess);

  useEffect(() => {
    pageContentService.getPageContent().then(response => setPageContent(response.data));
  }, []);

  const onEducationOccasionActionSuccessful = (educationId, educationOccasionId, action) => {
    setPageContent(prev => {
      const pageContent = { ...prev };
      const educations = pageContent.educations;
      const education = educations.find(c => c.id === educationId);
      const isRegistration = action === EDUCATION_OCCASION_ACTION.REGISTER;
      education.educationOccasions = education.educationOccasions.map(occasion => {
        if (occasion.id === educationOccasionId) {
          return {
            ...occasion,
            isRegistered: isRegistration,
            currentNumberOfAttendees: isRegistration
              ? occasion.currentNumberOfAttendees + 1
              : occasion.currentNumberOfAttendees - 1,
          };
        }
        return occasion;
      });

      return pageContent;
    });
  };

  return (
    <>
      {hasComflowAccess && notDesktop && <LinkToComflow />}
      <Welcome title={pageContent?.title ? pageContent.title : ""} text={pageContent?.text}>
        <h2 className={styles.sectionTitle}>{pageContent?.hyperlinkTitle}</h2>
        {pageContent?.hyperlinks && (
          <ExternalLinkList
            externalLinks={pageContent.hyperlinks}
            target={Target.NEW_TAB}
            customCssClass={styles.fileList}
          />
        )}
        <h2 className={styles.sectionTitle}>{pageContent?.fileTitle}</h2>
        {pageContent?.files && <FileDownloadList files={pageContent.files} target={Target.NEW_TAB} />}
        {pageContent.educations && (
          <Educations
            educations={pageContent?.educations}
            onEducationOccasionActionSuccessful={onEducationOccasionActionSuccessful}
          />
        )}
      </Welcome>
    </>
  );
};

export default ContentWelcome;
