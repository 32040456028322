import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { isEmbedded } from "../../utils/embeddedUils";
import storage from "../../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE } from "../../utils/store/storeVariables";
import store from "../rootReducer";
import type { ApplicationItem } from "./sessionReducer";
import {
  APPLICATIONS_LIST,
  AUTHORIZATION_LIST,
  COMFLOW_SESSION_ID,
  EMBEDDED_SESSION_ID,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  MAIN_SESSION_ID,
  REMEMBER_ME,
  RESET_SESSION,
  RESET_SESSION_INITIALIZED,
  SESSION_ID,
  SINGLE_SIGN_OUT,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

export function resetComflowSessionId(): AnyAction {
  return { type: COMFLOW_SESSION_ID, undefined };
}

export function setSessionId(sessionId: string | null, rememberMe: boolean): void {
  if (isEmbedded) {
    store.dispatch({ type: EMBEDDED_SESSION_ID, sessionId });
    saveItem(EMBEDDED_SESSION_ID, sessionId, rememberMe);
  } else {
    store.dispatch({ type: MAIN_SESSION_ID, sessionId });
    saveItem(MAIN_SESSION_ID, sessionId, rememberMe);
  }

  store.dispatch({ type: SESSION_ID, sessionId });
  store.dispatch({ type: RESET_SESSION_INITIALIZED });
}

export function setComflowSessionId(comflowSessionId: string): PayloadAction<string> {
  return {
    type: COMFLOW_SESSION_ID,
    payload: comflowSessionId,
  };
}

export function setApplicationsList(applicationsList: ApplicationItem[]): PayloadAction<ApplicationItem[]> {
  return {
    type: APPLICATIONS_LIST,
    payload: applicationsList,
  };
}

export function setAuthorizationList(authorizationList: any): void {
  store.dispatch({ type: AUTHORIZATION_LIST, authorizationList });
}

export function setRememberMe(rememberMe: boolean): AnyAction {
  saveItem(REMEMBER_ME, rememberMe, rememberMe);

  return { type: REMEMBER_ME, rememberMe };
}

export function setLoginToken(loginToken: string | null): void {
  store.dispatch({ type: LOGIN_TOKEN, loginToken });
}

export function resetSession(): void {
  storage.deleteItem(MAIN_SESSION_ID);
  storage.deleteItem(EMBEDDED_SESSION_ID);
  storage.deleteItem(SINGLE_SIGN_ON_RESPONSE);
  store.dispatch({ type: RESET_SESSION });
}

export function setInitialized(initialized = true): void {
  store.dispatch({ type: INITIALIZED, initialized });
}

export function setUserHasMessageToAccept(message: string): void {
  store.dispatch({ type: USER_HAS_MESSAGE_TO_ACCEPT, message });
}

function saveItem(variableId: string, value: any, rememberMe: boolean): void {
  storage.saveItem(variableId, value, rememberMe);
}

export function setIsSingleSignOn(isSingleSignOn: boolean): AnyAction {
  return {
    type: IS_SINGLE_SIGN_ON,
    isSingleSignOn,
  };
}

export function setSingleSignOut(): AnyAction {
  return {
    type: SINGLE_SIGN_OUT,
    singleSignOut: true,
  };
}
