import { Button, THEME } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { AdvanceContactInformation, UserInformation } from "../../shared/RecieverInformation/RecieverInformation";
import styles from "./ClientInfo.module.scss";
import EditClientModal from "./EditClientModal";

function ClientInfo({ receiver, setBreadcrumbs, referrer, fromReceiverMenu, setFetchReceiver }) {
  const translate = useTranslate();
  const [modal, setModal] = useState("");

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_INFO.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_INFO.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, fromReceiverMenu, translate]);

  return (
    <>
      <section className={styles.split}>
        <div>
          <UserInformation receiver={receiver} title={translate("DETAILS")} showDeregistrationReason />
        </div>
        <div>
          <AdvanceContactInformation shouldContact={receiver?.useAdvanceContact} disabled={true} />
        </div>
        <Button theme={THEME.SECONDARY} onClick={() => setModal("EDIT")}>
          {translate("EDIT_CLIENT")}
        </Button>
      </section>
      <EditClientModal
        client={receiver}
        visible={modal === "EDIT"}
        onClose={() => setModal("")}
        setFetchReceiver={setFetchReceiver}
      />
    </>
  );
}

ClientInfo.propTypes = {
  receiver: PropType.object,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
  setFetchReceiver: PropType.func,
};
export default ClientInfo;
