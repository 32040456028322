export function getMessageFromSessionErrorCodes(errorCode) {
  let message = "";
  Object.keys(SESSION_INVALID).forEach(key => {
    if (errorCode === SESSION_INVALID[key].CODE) {
      message = SESSION_INVALID[key].MESSAGE;
    }
  });
  return message;
}

const SESSION_INVALID = {
  SESSION_EXPIRED: {
    CODE: "OTSS000",
    MESSAGE: "SESSION_EXPIRED",
  },
  PASSWORD_EXPIRED: {
    CODE: "OTSP001",
    MESSAGE: "PASSWORD_EXPIRED",
  },
  INVALID_USER_OR_PASSWORD: {
    CODE: "OTSU001",
    MESSAGE: "INVALID_USER_OR_PASSWORD",
  },
  USER_DISABLED: {
    CODE: "OTSU002",
    MESSAGE: "USER_DISABLED",
  },
  LOGIN_TOKEN_INVALID: {
    CODE: "token.is.revoked",
    MESSAGE: "LOGIN_TOKEN_INVALID",
  },
};

export default { SESSION_INVALID };
