import { ErrorBoundary } from "@myloc/myloc-utils";
import { Provider } from "react-redux";
import Site from "./components/Site/Site";
import store from "./reducers/rootReducer";

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Site />
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
