import { HEADER, HeaderOptions, RestOptions } from "@myloc/myloc-utils";
import i18n from "../../language/i18n";
import { EMBEDDED_SESSION_ID, MAIN_SESSION_ID } from "../../reducers/session/sessionVariables";
import { isEmbedded } from "../../utils/embeddedUils";
import storage from "../../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE } from "../../utils/store/storeVariables";
import { handleError } from "../error/errorService";

const SSO_HEADER_OPTIONS = {
  ISS: "OIDC_CLAIM_iss",
  UPN: "OIDC_CLAIM_upn",
  GIVEN_NAME: "OIDC_CLAIM_given_name",
  FAMILY_NAME: "OIDC_CLAIM_family_name",
  AUD: "OIDC_CLAIM_aud",
  SUB: "OIDC_CLAIM_sub",
  TID: "OIDC_CLAIM_tid",
  UNIQUE_NAME: "OIDC_CLAIM_unique_name",
};

type CustomErrorHandler = (error: any) => void;

/**
 * Pass a custom Error handler function when needed - see customErrorHandler example in accountService.resetPassword
 * @param customErrorHandler - optional error handler function
 * @returns RestOptions
 */
const defaultRestOptions = async (customErrorHandler?: CustomErrorHandler): Promise<RestOptions> => {
  const sessionId = await storage.loadItem(isEmbedded ? EMBEDDED_SESSION_ID : MAIN_SESSION_ID);

  const options = new RestOptions();

  options.errorService = customErrorHandler ?? handleError;

  options.headerOptions = new HeaderOptions();

  if (sessionId) options.headerOptions.setHeader(HEADER.SESSIONID, sessionId);

  const singleSignOnUserData = await storage.loadItem(SINGLE_SIGN_ON_RESPONSE);

  if (singleSignOnUserData?.tid) {
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.ISS, singleSignOnUserData.iss);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.UPN, singleSignOnUserData.upn);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.GIVEN_NAME, singleSignOnUserData.given_name);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.FAMILY_NAME, singleSignOnUserData.family_name);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.AUD, singleSignOnUserData.aud);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.SUB, singleSignOnUserData.sub);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.TID, singleSignOnUserData.tid);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.UNIQUE_NAME, singleSignOnUserData.unique_name);
  }

  options.headerOptions.setHeader(HEADER.LANGUAGE, i18n.language);

  return options;
};

export default defaultRestOptions;
